<template  @click="toggleMenu = !true">
  <nav class="navbar  is-transparent pt-4 pb-4">
    <div class="max-width m-auto" style="display: flex; width: 100%;">
      <div class="navbar-brand max-width m-auto p-2">
        <a class="navbar-item " href="/">
          {{ $t("brand_name") }}
        </a>

        <div
        @click="toggleMenu = !toggleMenu"
        class="navbar-burger"
        :class="burgerStatus"
        data-target="navbarExampleTransparentExample"
      >
        <span></span>
        <span></span>
        <span></span>
        </div>

      </div>

      <div id="navbarExampleTransparentExample" class="navbar-menu" :class="toggleMenu ? 'open' : 'closed'">
        <div class="navbar-end is-justify-content-center is-align-items-center">
        <div class="navbar-item is-hoverable">
          <router-link @click="toggleMenu = false" class="navbar-item" to="/" id="logo-icon"
            >{{ $t("nav_option_home") }}
          </router-link>
          <router-link @click="toggleMenu = false" class="navbar-item" to="/about" id="logo-icon">
            {{ $t("nav_option_about") }}</router-link
          >
          <!-- <router-link @click="toggleMenu = false" class="navbar-item" to="/reviews">
            {{ $t("nav_option_reviews") }}
          </router-link> -->
          <router-link @click="toggleMenu = false" class="navbar-item" to="/contact">
            {{ $t("nav_option_contact") }}
          </router-link>
        <div class="navbar-item dropdown is-hoverable">
          <div class="dropdown-trigger is-flex is-align-items-center" >
            <button class="button is-small is-rounded has-text-white" style="background-color: unset;" aria-haspopup="true" aria-controls="dropdown-menu4">
              <span>{{ $i18n.locale }}</span>
              <span class="icon is-small" style="fill: var(--primary);">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content" style="background-color: #000000ad;">
              <div class="dropdown-item" v-for="locale in $i18n.availableLocales" :key="locale">
                <h6 :class="locale === $i18n.locale ? 'has-text-primary' : ' has-text-white'" class="is-clickable" @click="setLang(locale)">{{locale}}</h6>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </nav>
  <div>
    <router-view  :key="reRenderKey" />
      <footer class="footer spacing-top has-background-black-bis"  >
        <div class="max-width m-auto columns is-justify-content-space-between">
          <div class="column is-2" style="backdrop-filter: blur(0.9px);border-radius: 50px;">
          <h3 class="is-size-4 has-text-primary mb-1">{{$t('brand_name_short')}}</h3>
          <underline class="block"/>
          <p class="block has-text-white">{{$t('brand_desc_footer')}}</p>
            <p class="has-text-white mb-2">{{$t('footer_follow_us')}}</p>
          <ul class="pt-2 mt-3 is-flex is-justify-content-space-between">
            <li>
              <!-- FACEBOOK -->
              <button class="button is-primary" style="background-color: #4267B2;">
                <span class="icon">
                  <i class="fab fa-facebook fa-primary" style="color: black"></i>
                </span>
                <a  target="_blank" href="https://www.facebook.com/Jessy-Rent-O%C3%9C-110951554824292" class="has-text-white">Jessy Rent OÜ</a>
              </button>
            </li>
            <!-- INSTAGRAM -->
            <!-- <li>
              <button class="button is-primary" style="background-color: #E1306C;">
                <span class="icon">
                  <i class="fab fa-instagram fa-primary" style="color: black"></i>
                </span>
                <a  target="_blank" href="https://www.facebook.com/Jessy-Rent-O%C3%9C-110951554824292" class="has-text-white">@jgthms</a>
              </button>
            </li> -->
          </ul>
        </div>
          <div class="column is-3" style="backdrop-filter: blur(0.9px);border-radius: 50px;">
            <div class="navbar-item is-hoverable">
              <ul class="is-flex is-flex-direction-column">
              <h3 class="is-size-4 has-text-primary">{{$t('footer_site')}}</h3>
              <underline class="mb-4"/>
              
                  <router-link class=" navbar-item" to="/" id="logo-icon"
                    >{{ $t("nav_option_home") }}
                  </router-link>
                  <router-link class=" navbar-item" to="/about" id="logo-icon">
                    {{ $t("nav_option_about") }}</router-link
                  >
                  <!-- <router-link class=" navbar-item" to="/reviews">
                    {{ $t("nav_option_reviews") }}
                  </router-link> -->
                  <router-link class=" navbar-item" to="/contact">
                    {{ $t("nav_option_contact") }}
                  </router-link>
                  <router-link class=" navbar-item" to="/admin/login">
                    Log in
                  </router-link>
                  <a @click="openModal = true" class="navbar-item has-text-primary">
                    {{ $t("footer_place_review") }}
                  </a>
              </ul>
            </div>
          </div>
        <div class="column is-3" style="backdrop-filter: blur(0.9px);border-radius: 50px;">
          <h3 class="is-size-4 has-text-primary">{{ $t("nav_option_contact") }}</h3>
          <underline class="mb-4"/>
          <ul class="pl-3 pt-2">
            <!-- PHONE -->
            <li>
              <a :href="'tel:+' + siteData.phone" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-phone"></i>
                </span>
                <p class="link-hover navbar-item">+{{siteData.phone }}</p>
                </a>
              </li>

              <li>
              <a :href="'mailto:' + siteData.email" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-envelope"></i>
                </span>
                  <p class="link-hover mb-1 navbar-item">{{siteData.email }}</p>
                </a>
              </li>

            <li class="is-flex is-flex-direction-column">
              <a target="_blank" href="https://www.google.com/maps/dir//P%C3%A4inurme,+J%C3%A4rvamaa/@58.8024654,25.9104934,4355m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x46934e10fa8be2c7:0xa00b36f6fe61df0!2m2!1d25.9234964!2d58.8025505!3e0" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-location-dot"></i>
                </span>
                <p class=" mb-1 navbar-item link-hover">{{siteData.address}}</p>
                </a>
                <!-- <a target="_blank" href="https://www.google.com/maps/dir//P%C3%A4inurme,+J%C3%A4rvamaa/@58.8024654,25.9104934,4355m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x46934e10fa8be2c7:0xa00b36f6fe61df0!2m2!1d25.9234964!2d58.8025505!3e0">
                  <img src="../assets/img/maps.png" alt="">
                </a> -->
              </li>
              <li>
              <div class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-clock"></i>
                </span>
                <p class="has-text-white mb-1 navbar-item">{{siteData.time}}</p>
                </div>
              </li>
          </ul>
        </div>
        <!-- <div class="column is-4">
          <img class="image" src="@/assets/img/lily_pads.jpg" alt="">
        </div> -->
        </div>
      </footer>
  </div>
  <div class="modal"  :class="openModal ? 'is-active' : ''">
  <div class="modal-background"  @click="openModal = false"></div>
    <div class="modal-content" style="max-width:30rem">
      <form  :action="null">
        <input-box
          :placeholder="$t('review_form_placeholder_name')"
          type="text"
          :modelValue="review.author"
          @update:modelValue="review.author = $event"
          class="is-input-half"
          :label="$t('review_form_label_name')"
        />
        <input-box
          :placeholder="$t('review_form_placeholder_message')"
          type="text"
          :modelValue="review.message"
          @update:modelValue="review.message = $event"
          class="is-input-half"
          :label="$t('review_form_label_message')"
          required
        />
        <input-box
          :placeholder="$t('')"
          type="date"
          :modelValue="review.date"
          @update:modelValue="review.date = $event"
          class="is-input-half"
          :label="$t('review_form_label_date')"
          required
        />
          <label class="has-text-weight-bold" for="">{{$t('review_form_label_rating')}}</label>
        <div class="mb-4">
          <span class="fa fa-star is-clickable" @click="setRating(1)" :class="rating >= 1 ? 'checked' : ''"></span>
          <span class="fa fa-star is-clickable" @click="setRating(2)" :class="rating >= 2 ? 'checked' : ''"></span>
          <span class="fa fa-star is-clickable" @click="setRating(3)" :class="rating >= 3 ? 'checked' : ''"></span>
          <span class="fa fa-star is-clickable" @click="setRating(4)" :class="rating >= 4 ? 'checked' : ''"></span>
          <span class="fa fa-star is-clickable" @click="setRating(5)" :class="rating >= 5 ? 'checked' : ''"></span>
        </div>
        <p class="button is-small is-rounded" @click="addReview()">{{$t('review_form_post')}}</p>
      </form>
      <span>Reviews will be verified before being published.</span>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="openModal = false"></button>
</div>
</template>

<script>
import { setData, getSiteData } from "../firebase/FirestoreAPI";
export default {
  data() {
    return {
      paymentModal: false,
      locationsToPay: "test",
      sites: [],
      reRenderKey: 1,
      toggleMenu: false,
      review: {},
      toggleDropDown: false,
      siteData: '',
      openModal: false,
      rating: 0
    };
  },

  methods: {
         async getSiteData() {
          let res = await getSiteData()
          if(res){
            this.siteData = res
          }
        },

        async addReview() {
          const payload = {
            author: this.review.author ? this.review.author : 'Annonymous', 
              message: this.review.message,
              rating: this.review.rating,
              date: this.review.date,
              approved: false,
          }
          setData(payload, "reviews")
          .then(() => {
            this.openModal = false
          })
        },
        
        setRating(rating) {
          if(rating == 1 && this.rating == 1){
             return this.rating = 0
          }
          this.rating = rating
          this.review.rating = rating
        },
        setLang(locale){
          this.$i18n.locale = locale
          this.state.store.lang = locale
        },
        scrollToTop() {
         window.scrollTo(0,0);
        },
  },
  computed: {
    burgerStatus() {
      if (this.toggleMenu) {
        return "open is-active";
      } else {
        return "";
      }
    },
  },
  onload() {
    this.scrollToTop();
  },
  mounted() {
    this.getSiteData();
  },
  
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.navbar{
  position: fixed;
  width: 100%;
  background-color: #000000ad;
  backdrop-filter: blur(5px);
}
.navbar-brand {
  width: 100%;
 @media (min-width: 1024px) {
   width: unset;
 }
}
.navbar-menu.open {
display: flex;
position: fixed;
right: 0;
top: 6.25rem;
background-color: #000000ad;
animation: open;
animation-duration: 0.125s;
padding: 1rem;
@media (min-width: 1024px) {
display: flex;
position: unset;
right: unset;
top: unset;
background-color: unset;
}
}
.navbar-menu.closed{
display: flex;
position: fixed;
right: -20rem;
top: 6.25rem;
background-color: #000000ad;
animation: close;
animation-duration: 0.125s;
padding: 1rem;
@media (min-width: 1024px) {
display: flex;
position: unset;
right: unset;
top: unset;
background-color: unset;
}
}
.footer{
  padding: 3rem 1.5rem 3rem;
}
.menu-item span {
  width: 25%;
  max-width: 1.5rem;
}

.dropdown-content {
  max-height: 12rem;
  overflow: auto;
}

.navbar-burger {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
@keyframes open {
  from {right: -20rem;}
  to {right: 0;}
}
@keyframes close {
  from {right: 0;}
  to {right: -20rem;}
}
</style>
